'use client';
import React, { useEffect, useState } from 'react';
import CrossIcon from '@images/small_icon/sign-up-close-icon.svg';
import { Language } from '@app/lib/store-context/Context';
import {
    Button,
    Dialog,
    DialogClose,
    DialogContent,
    DialogTitle,
    DialogTrigger,
} from 'mmds';
import setIsDismissedCookieValue from '@app/components/ui/utils/set-is-dissmissed-cookie-value';
import getIsDismissedCookieValue from '@app/components/ui/utils/get-is-dissmissed-cookie-value';
import cn from 'clsx';
import s from './us-content-modal.module.css';

const UsContentModal = ({ lang }: { lang: Language }) => {
    const [open, setOpen] = useState<boolean>(false);
    useEffect(() => {
        const isDismissedCookieValue =
            getIsDismissedCookieValue('UsContentModal');
        if (isDismissedCookieValue) {
            return;
        }
        const timer = setTimeout(() => {
            setOpen(true);
        }, 3000);
        return () => clearTimeout(timer);
    }, [lang]);

    const onOpenChange = (value: boolean) => {
        setOpen(value);
        if (!value) {
            setIsDismissedCookieValue('UsContentModal');
        }
    };
    if (lang !== 'en-US') return null;
    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogTrigger asChild>
                <button style={{ display: 'none' }}>Open Signup</button>
            </DialogTrigger>
            <DialogTitle title="Sign Up" />
            <DialogContent >
                <div className={s.dialogContent}>
                    <div className={cn(s.image, s.desktopOnly)}>
                        <img
                            src="https://cdn.shopify.com/s/files/1/0510/7809/files/sign-up-desktop.jpg?v=1729814136"
                            alt="sign-up"
                        />
                    </div>
                    <div className={cn(s.image, s.mobileOnly)}>
                        <img
                            src="https://cdn.shopify.com/s/files/1/0510/7809/files/sign-up-pop-up-mobile.jpg?v=1729814151"
                            alt="sign-up"
                        />
                        <DialogClose>
                            <div className={cn(s.close, s.mobileOnly)}>
                                <img
                                    className={s.closeIcon}
                                    src={CrossIcon.src}
                                    alt="Cross Icon"
                                />
                            </div>
                        </DialogClose>
                    </div>
                    <div className={s.content}>
                        <DialogClose>
                            <div className={cn(s.close, s.desktopOnly)}>
                                <img
                                    className={s.closeIcon}
                                    src={CrossIcon.src}
                                    alt="Cross Icon"
                                />
                            </div>
                        </DialogClose>
                        <div className={cn('mmds-subtitle-one', s.header)}>
                            No Surprise Duties. No Extra Fees.
                        </div>
                        <div className="mmds-copy-two">
                            All MAAP USA orders are shipped from our California
                            warehouse — so your order won’t be affected by
                            recent tariff changes.
                        </div>
                        <div className={s.button}>
                            <DialogClose>
                                <Button variant="base" label="Got it" />
                            </DialogClose>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default UsContentModal;
